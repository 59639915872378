
import { defineComponent, onMounted } from 'vue';

import { useRoute } from 'vue-router';

import { ElLoading } from 'element-plus';

import DashboardLayout from '@/layouts/DashboardLayout.vue';

import Table from '@/components/Shared/Table.vue';

import useGrid from '@/uses/useGrid';
import useView from '@/uses/useView';
import { IFilter } from '@/interfaces/Filter';

export default defineComponent({
  name: 'Search',

  components: {
    DashboardLayout,
    Table,
  },

  setup() {
    const route = useRoute();

    const {
      columns,
      fetchView,
    } = useView();

    const {
      grid,
      gridRequest,
      fetchGridData,
      setGridRequestFilters,
    } = useGrid();

    onMounted(async () => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        const {
          viewName,
          gridName,
          filterField,
          filterValue,
        } = route.query;
        if (viewName && gridName && filterField && filterValue) {
          await fetchView(viewName.toString());

          // get column
          const column = columns.value.find((el) => el.id === filterField);

          if (column) {
            const filter: IFilter = {
              id: filterField.toString(),
              type: column.type,
              index: column.index,
              visible: column.visible,
              field_id: filterField.toString(),
              operation: 'like',
              data_source: '',
              filter_text: filterValue.toString(),
              header_text: column.header_text,
              default_filter: '',
            };

            setGridRequestFilters([filter]);
          }

          await fetchGridData({
            viewName: viewName.toString(),
            gridName: gridName.toString(),
            gridRequest: gridRequest.value,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    });

    return {
      columns,
      grid,
    };
  },
});
