import { useStore } from 'vuex';
import { computed, ComputedRef } from 'vue';

import { IGridResponse, IGridRequest } from '@/interfaces/Grid';
import { IDrilldownRequest } from '@/interfaces/Drilldown';
import { IColumn } from '@/interfaces/View';
import { IFilter } from '@/interfaces/Filter';
import { IOrderRequest } from '@/interfaces/Order';
import { IDrillThroughRequest } from '@/interfaces/DrillThrough';

export default function useGrid(): {
  filters: ComputedRef<IFilter[]>;
  grid: ComputedRef<IGridResponse>;
  gridRequest: ComputedRef<IGridRequest>;
  resetGridRequest(): void;
  addGridRequestFilter: (payload: IFilter) => void;
  removeGridRequestFilter: (payload: IFilter) => void;
  removeGridColumnRequestFilters: (payload: string) => void;
  removeGridRequestFilters: () => void;
  setGridRequestFilters(payload: IFilter[]): void;
  setGridRequestOrders: (payload: IOrderRequest) => void;
  setGridRequestPageSize: (payload: number) => void;
  setGridRequestPageIndex: (payload: number) => void;
  setGridRequestDrillThrough(payload: IDrillThroughRequest | null): void;
  setGridRequestDrillThroughParentView: (payload: string) => void;
  setGridRequestDrillThroughParentComponent: (payload: string) => void;
  setGridRequestDrillThroughDataRow(payload: Record<string, unknown>): void;
  fetchGridData: (payload: {
    viewName: string;
    gridName: string;
    gridRequest: IGridRequest;
  }) => Promise<void>;
  updateGridColumns: (payload: {
    viewName: string;
    componentName: string;
    columns: IColumn[];
  }) => Promise<void>;
  updateGridFilters: (payload: {
    viewName: string;
    componentName: string;
    filters: IFilter[];
  }) => Promise<void>;
  updateGridOrders: (payload: {
    viewName: string;
    componentName: string;
    orders: IOrderRequest[];
  }) => Promise<void>;
  fetchDrilldownData: (payload: {
    viewName: string;
    drilldownName: string;
    drilldownRequest: IDrilldownRequest;
  }) => Promise<IGridResponse>;
  } {
  const store = useStore();

  const filters = computed(():IFilter[] => store.getters['Grid/getFilters']);

  const gridRequest = computed((): IGridRequest => store.getters['Grid/getGridRequest']);

  const grid = computed(():IGridResponse => store.getters['Grid/getGridResponse']);

  function resetGridRequest(): void {
    store.commit('Grid/RESET_GRID_REQUEST');
  }

  function addGridRequestFilter(payload: IFilter): void {
    store.commit('Grid/ADD_GRID_REQUEST_FILTER', payload);
  }

  function setGridRequestFilters(payload: IFilter[]): void {
    store.commit('Grid/SET_GRID_REQUEST_FILTERS', payload);
  }

  function setGridRequestOrders(payload: IOrderRequest): void {
    store.commit('Grid/SET_GRID_REQUEST_ORDERS', [payload]);
  }

  function setGridRequestPageSize(payload: number): void {
    store.commit('Grid/SET_GRID_REQUEST_PAGE_SIZE', payload);
  }

  function setGridRequestPageIndex(payload: number): void {
    store.commit('Grid/SET_GRID_REQUEST_PAGE_INDEX', payload);
  }

  function setGridRequestDrillThrough(payload: IDrillThroughRequest | null): void {
    store.commit('Grid/SET_GRID_REQUEST_DRILL_THROUGH', payload);
  }

  function setGridRequestDrillThroughParentView(payload: string): void {
    store.commit('Grid/SET_GRID_REQUEST_DRILL_THROUGH_PARENT_VIEW', payload);
  }

  function setGridRequestDrillThroughParentComponent(payload: string): void {
    store.commit('Grid/SET_GRID_REQUEST_DRILL_THROUGH_PARENT_COMPONENT', payload);
  }

  function setGridRequestDrillThroughDataRow(payload: Record<string, unknown>): void {
    store.commit('Grid/SET_GRID_REQUEST_DRILL_THROUGH_DATA_ROW', payload);
  }

  function removeGridColumnRequestFilters(payload: string): void {
    store.commit('Grid/REMOVE_GRID_COLUMN_REQUEST_FILTERS', payload);
  }

  function removeGridRequestFilter(payload: IFilter): void {
    store.commit('Grid/REMOVE_GRID_REQUEST_FILTER', payload);
  }

  function removeGridRequestFilters(): void {
    store.commit('Grid/REMOVE_GRID_REQUEST_FILTERS');
  }

  async function fetchGridData(
    payload: {viewName: string; gridName: string; gridRequest: IGridRequest},
  ): Promise<void> {
    try {
      await store.dispatch('Grid/fetchGridData', payload);
    } catch (error) {
      console.error(error);
    }
  }

  async function updateGridColumns(
    payload: { viewName: string; componentName: string; columns: IColumn[]; },
  ) {
    try {
      await store.dispatch('Grid/updateGridColumns', payload);
    } catch (error) {
      console.error(error);
    }
  }

  async function updateGridFilters(
    payload: { viewName: string; componentName: string; filters: IFilter[]; },
  ) {
    try {
      await store.dispatch('Grid/updateGridFilters', payload);
    } catch (error) {
      console.error(error);
    }
  }

  async function updateGridOrders(
    payload: { viewName: string; componentName: string; orders: IOrderRequest[]; },
  ) {
    try {
      await store.dispatch('Grid/updateGridOrders', payload);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchDrilldownData(
    payload: {viewName: string; drilldownName: string; drilldownRequest: IDrilldownRequest},
  ): Promise<IGridResponse> {
    try {
      return await store.dispatch('Grid/fetchDrilldownData', payload);
    } catch (error) {
      throw error.response;
    }
  }

  return {
    filters,
    grid,
    gridRequest,
    fetchGridData,
    resetGridRequest,
    addGridRequestFilter,
    removeGridRequestFilter,
    removeGridColumnRequestFilters,
    removeGridRequestFilters,
    setGridRequestFilters,
    setGridRequestOrders,
    setGridRequestPageSize,
    setGridRequestPageIndex,
    setGridRequestDrillThrough,
    setGridRequestDrillThroughParentView,
    setGridRequestDrillThroughParentComponent,
    setGridRequestDrillThroughDataRow,
    updateGridColumns,
    updateGridFilters,
    updateGridOrders,
    fetchDrilldownData,
  };
}
